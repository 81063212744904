<script setup lang="ts">
import {
  AutoAssignType,
  checkIsExpressionDefault,
} from '@component-library/business-model/auto-assign';
import type { ExpressionDefault } from '@component-library/business-model/auto-assign';
import { computed } from 'vue';
import { GatherField } from '@component-library/gather';
import { FieldTypeIds } from '@component-library/fields';

const props = defineProps<{
  field: GatherField;
  fields: GatherField[];
}>();
const emit = defineEmits(['updateOptions']);

const expressionDefault = computed<ExpressionDefault | null>(() => {
  return (props.field.options?.defaults?.find((d) =>
    checkIsExpressionDefault(d)
  ) ?? null) as ExpressionDefault | null;
});

const expressionFields = computed<GatherField[]>(() => {
  return props.fields.filter(
    (f) => f.field_type_id === FieldTypeIds.EXPRESSION
  );
});

function updateDefault({ prop, value }: { prop: string; value: number }) {
  const ed = expressionDefault.value;
  const nextDefaults = [...(props.field.options?.defaults ?? [])];
  nextDefaults.splice(nextDefaults.indexOf(ed!), 1, { ...ed!, [prop]: value });
  emit('updateOptions', {
    ...props.field.options,
    defaults: nextDefaults,
  });
}

function getSelectedExpressionFieldId(evt: Event): number {
  return parseInt((evt.target as HTMLInputElement).value, 10);
}

function handleAdd(evt: Event) {
  const options = {
    ...props.field.options,
    defaults: [
      ...(props.field.options?.defaults ?? []),
      {
        type: AutoAssignType.EXPRESSION,
        fieldId: expressionFields.value[0].id,
      },
    ],
  };
  emit('updateOptions', options);
}

function handleDelete() {
  const nextDefaults = [...(props.field.options?.defaults ?? [])];
  nextDefaults.splice(nextDefaults.indexOf(expressionDefault.value!), 1);
  emit('updateOptions', {
    ...props.field.options,
    defaults: nextDefaults,
  });
}
</script>

<template>
  <div>
    <div
      v-if="expressionFields.length"
      class="d-flex flex-row align-items-center mb-3"
    >
      <label class="form-label me-auto mb-0">
        Automatically assign a default value by binding to an Expression field
      </label>
      <button
        type="button"
        class="btn btn-outline-primary btn-sm ms-2"
        @click="handleAdd"
      >
        <i class="fas fa-plus m-1" />
      </button>
    </div>

    <div v-if="expressionDefault" class="input-group">
      <!-- Select for a Dropdown field as an operand -->
      <select
        class="form-control"
        :value="expressionDefault.fieldId"
        @change="
          (evt) =>
            updateDefault({
              prop: 'fieldId',
              value: getSelectedExpressionFieldId(evt),
            })
        "
      >
        <option
          v-for="ef in expressionFields"
          :key="`expressionField-${ef.id}`"
          :value="ef.id"
        >
          {{ ef.label }}
        </option>
      </select>

      <button
        type="button"
        class="btn btn-outline-danger"
        @click="handleDelete"
      >
        <i class="fas fa-trash-alt" />
      </button>
    </div>
  </div>
</template>
