<template>
  <div>
    <div class="d-flex flex-column h-100">
      <label id="file">
        {{ labelText }}
        <br v-if="labelText" />
        <small class="text-muted">
          Supported types:
          <span class="fw-medium">{{ supportedType.join(', ') }}</span>
        </small>
      </label>

      <form
        class="file_box d-flex align-items-center justify-content-center flex-grow-1 has-dragger mt-2"
        :ref="getActionType('DragRef')"
        :class="{ 'is-dragover': isDraggingOver }"
        :style="{ 'border-color': uploadBorderColour }"
      >
        <div class="dragger-inner w-100">
          <div class="upload-icon" v-show="!hideIcon">
            <i :class="uploadIcon" :style="{ color: uploadIconColour }"></i>
          </div>

          <h6 class="uploader-text mt-1" data-cy="uploader-text">
            {{ uploadText }}
          </h6>

          <div
            v-if="(pending || completedUpload) && !hideProgress"
            class="progress upload-progress mt-3"
          >
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              :style="{ width: progress + '%' }"
            >
              {{ progress }}%
            </div>
          </div>

          <div v-if="isImageUpload && imageUrl != null" class="mt-3 mb-3">
            <img v-if="imageUrl" loading="lazy" :src="imageUrl" width="60%" />
          </div>

          <div
            v-if="
              !pending &&
              !completedUpload &&
              (supportsMultiple ? allFiles.length <= 1 : true)
            "
            class="optional mt-2 fw-medium"
          >
            <label
              class="browse-files me-1 text-decoration-underline clickable"
              :for="getActionType('Id')"
              @click="removeFile"
              v-show="showBrowseButton"
              :disabled="disableButton"
            >
              Click to upload
            </label>

            <span class="me-1">{{ optionalText }}</span>

            <label
              class="remove-file text-danger text-decoration-underline clickable"
              @click.prevent="clearFile"
              v-show="!showBrowseButton"
              :disabled="disableButton"
            >
              {{ deleteButtonText }}
            </label>

            <input
              type="file"
              :id="getActionType('Id')"
              :multiple="supportsMultiple"
              class="form-control-range d-none"
              :ref="getActionType('ClickRef')"
              @change="handleFileUpload($event)"
            />
          </div>
        </div>
      </form>
    </div>

    <div v-if="uploads && uploads.length > 0" class="mt-2">
      <div class="table-responsive uploads-table custom-scrollbar">
        <table class="table table-condensed">
          <tbody>
            <tr v-for="(upload, uploadIndex) in uploads" :key="uploadIndex">
              <td>
                <div class="d-flex align-items-center justify-content-center">
                  <h6
                    class="fal mb-0"
                    :class="getUploadIconFromType(upload.type)"
                  />
                </div>
              </td>
              <td style="word-break: break-all">
                <span
                  v-if="!upload.is_editing"
                  @dblclick="setEditingUpload(upload)"
                >
                  {{ upload.display_name || upload.file_name }}
                </span>
                <input
                  v-else
                  type="text"
                  class="form-control form-control-sm"
                  :value="upload.display_name || upload.file_name"
                  @keyup.enter="updateFileName($event, upload)"
                  @blur="updateFileName($event, upload)"
                />
              </td>
              <td class="text-center">
                <button
                  class="btn btn-sm btn-danger"
                  @click="$emit('deleteUpload', upload.id)"
                >
                  <i class="fal fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="supportsMultiple && allFiles.length > 0" class="mt-2">
      <span class="d-block fw-medium mb-2"> Uploads: </span>

      <div class="list-group custom-scrollbar" style="max-height: 350px">
        <div
          v-for="(file, fileIndex) in allFiles"
          class="list-group-item bg-light border-0"
          :class="{
            'mb-2': fileIndex !== allFiles.length - 1,
          }"
        >
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <h4
                class="fal mb-0 me-3"
                :class="getUploadIconFromType(file.file.type)"
              />
              <div class="d-flex flex-column">
                <span class="fw-medium">
                  {{ file.file.name }}
                  <template v-if="file.sheet">({{ file.sheet }})</template>
                </span>
                <small class="text-muted">
                  {{ formatFileSize(file.file.size) }}
                </small>
              </div>
            </div>

            <button
              class="btn btn-sm btn-danger"
              @click.prevent="removeFileByIndex(fileIndex)"
            >
              <i class="fal fa-trash-alt"></i>
            </button>
          </div>

          <div
            v-if="file.pending || file.complete || file.error"
            class="progress mt-1"
            style="height: 3px"
          >
            <div
              class="progress-bar"
              :class="{
                'bg-success': file.complete && !file.error,
                'bg-danger': file.error,
              }"
              :style="{ width: file.progress + '%' }"
            />
          </div>

          <small v-if="file.error" class="text-danger">
            {{ file.error }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import makeId from '../local-id.mjs';
import { getUploadIconFromType, formatBytes } from '../store/files';

export default {
  name: 'FileUploader',
  props: [
    'supportedType',
    'labelText',
    'pending',
    'error',
    'errorMessage',
    'complete',
    'progress',
    'customCompleteMessage',
    'maxFileSize',
    'uploads',
    'hideIcon',
    'placeholderImage',
    'isImageUpload',
    'dontAutoResetUpload',
    'supportsMultiple',
    'files',
    'hideProgress',
  ],
  data: () => ({
    dragAndDropCapable: false,
    file: null,
    hasError: false,
    hasUpload: false,
    uploadIcon: 'fal fa-file-upload',
    uploadText: 'Drag & Drop',
    optionalText: 'or drag & drop',
    showBrowseButton: true,
    disableButton: false,
    deleteButtonText: 'remove',
    uploadBorderColour: '#c2cdda',
    uploadIconColour: '#3366a2',
    completedUpload: false,
    isDraggingOver: false,
    imageUrl: null,
    containerId: makeId(),
  }),
  watch: {
    pending(newVal) {
      if (!newVal) return;

      this.updateState('file-uploading');
    },
    complete(newVal) {
      if (!newVal) return;

      this.completedUpload = true;

      if (this.customCompleteMessage) {
        this.updateState('file-uploaded-custom');
        this.resetProgress();
        return;
      }

      setTimeout(() => {
        this.updateState('file-uploaded');
        this.resetProgress();
      }, 1500);

      if (!this.dontAutoResetUpload) {
        setTimeout(() => {
          if (this.resetUpload) {
            this.resetUpload = false;
            return;
          }

          this.updateState('awaiting-upload');
        }, 5000);
      }
    },
    error(newVal) {
      if (!newVal) {
        return;
      }

      this.updateState('error', this.errorMessage || this.error);
      this.resetProgress();
    },
    placeholderImage(newVal) {
      this.imageUrl = newVal;
    },
  },
  computed: {
    getMaxFileSize() {
      return this.maxFileSize || 10;
    },
    allFiles() {
      return this.files || [];
    },
  },
  methods: {
    handleFileUpload(e) {
      this.updateDragAndDrop(this.$refs[this.getActionType('ClickRef')].files);
    },
    updateState(state, message) {
      this.disableButton = false;

      switch (state) {
        case 'awaiting-upload':
          this.showBrowseButton = true;
          this.uploadBorderColour = '#c2cdda';
          this.uploadIconColour = '#3366a2';
          this.uploadText = 'Drag & Drop';
          this.deleteButtonText = 'remove';
          this.uploadIcon = 'fal fa-file-upload';
          break;
        case 'file-ready':
          this.showBrowseButton = false;
          this.uploadBorderColour = '#3366a2';
          this.uploadIconColour = '#3366a2';
          if (this.allFiles.length > 1) {
            this.uploadText =
              [...this.allFiles].map((f) => f.file.name).join(', ') +
              ' is ready.';
          } else {
            this.uploadText = this.file.name + ' is ready.';
          }
          this.deleteButtonText = 'Remove File';
          this.uploadIcon = this.getFileTypeIcon(this.file.name);
          break;
        case 'file-uploading':
          this.showBrowseButton = false;
          this.uploadBorderColour = '#3366a2';
          this.uploadIconColour = '#3366a2';
          this.uploadText = 'Uploading';
          this.uploadIcon = 'spinner-border spinner-border-lg';
          this.disableButton = true;
          break;
        case 'file-uploaded':
          this.showBrowseButton = false;
          this.uploadBorderColour = '#3366a2';
          this.uploadIconColour = '#3366a2';
          this.uploadText = 'File has been uploaded.';
          this.deleteButtonText = 'Remove File';
          this.uploadIcon = 'fal fa-check';
          break;
        case 'file-uploaded-custom':
          this.showBrowseButton = false;
          this.uploadBorderColour = '#138496';
          this.uploadIconColour = '#138496';
          this.uploadText = this.customCompleteMessage;
          this.deleteButtonText = 'Remove File';
          this.uploadIcon = 'fal fa-check';
          break;
        case 'error':
          this.showBrowseButton = true;
          this.uploadBorderColour = '#dc3545';
          this.uploadIconColour = '#dc3545';
          this.uploadText = message;
          this.deleteButtonText = 'remove';
          this.uploadIcon = 'fal fa-file-upload';
          break;
      }

      this.optionalText =
        this.hasUpload && !this.hasError
          ? ''
          : this.showBrowseButton
          ? 'or drag & drop'
          : this.supportsMultiple
          ? 'Drag & drop more files or '
          : '';
    },
    clearFile() {
      this.resetUpload = true;

      this.removeFile();
    },
    removeFile() {
      if (!this.file) {
        this.updateState('awaiting-upload');
        return;
      }

      this.file = null;

      this.$emit('uploader', this.file);

      this.$refs[this.getActionType('ClickRef')].files = null;
      this.$refs[this.getActionType('ClickRef')].value = null;
      this.$refs[this.getActionType('DragRef')].files = null;
      this.imageUrl = null;

      this.hasUpload = false;

      this.updateState('awaiting-upload');
    },
    removeFileByIndex(index) {
      this.$emit('removeFileByIndex', index);

      if (this.allFiles.length === 1) {
        this.removeFile();
        return;
      }

      this.file = this.allFiles[0].file;
      this.updateState('file-ready');
    },
    updateDragAndDrop(files) {
      if (files.length == 0) {
        return;
      }

      if (!this.supportsMultiple && files.length > 1) {
        this.hasError = true;
        this.updateState(
          'error',
          'You must only upload one file at a time, try again.'
        );
        return;
      }

      for (let i = 0; i < files.length; i++) {
        if (files[i].size > this.getMaxFileSize * 1000000) {
          // Convert MB to B
          this.updateState(
            'error',
            'File too large. Maximum supported size: ' +
              this.getMaxFileSize +
              'MB'
          );
          return;
        }

        if (!this.validateFileType(files[i])) {
          this.updateState(
            'error',
            'You must use one of these file types "' +
              this.supportedType +
              '", try again.'
          );
          return;
        }
      }

      files = [...this.allFiles.map((f) => f.file), ...files];
      this.file = files[0];

      this.updateState('file-ready');

      if (this.file && this.file.type.startsWith('image/')) {
        this.imageUrl = URL.createObjectURL(this.file);
      }

      this.$emit('uploader', files.length === 1 ? this.file : files);

      this.hasUpload = true;
    },
    hasDragOver(dragOver) {
      if (!dragOver) {
        this.isDraggingOver = false;
        return;
      }
      this.isDraggingOver = true;
    },
    validateFileType(file) {
      let fileExtension = file.name
        .substr(file.name.lastIndexOf('.') + 1)
        .toLowerCase();

      if (this.supportedType.indexOf(fileExtension) == -1) {
        return false;
      }

      return true;
    },
    getFileTypeIcon(filename) {
      let type =
        filename.substring(filename.lastIndexOf('.') + 1, filename.length) ||
        filename;

      switch (type) {
        case 'csv':
          return 'fal fa-file-csv';
        case 'xlsx':
        case 'xls':
          return 'fal fa-file-excel';
        case 'png':
        case 'jpeg':
        case 'jpg':
          return 'fal fa-file-image';
        case 'zip':
          return 'fal fa-file-archive';
      }
      return 'fal fa-file';
    },
    getActionType(type) {
      return this.containerId + '' + type;
    },
    resetProgress() {
      this.completedUpload = false;
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div');

      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    },
    setEditingUpload(upload) {
      this.$set(upload, 'is_editing', true);
    },
    updateFileName(event, upload) {
      this.$emit('updateFileName', {
        upload,
        file_name: event.target.value.replace(/\s/g, ''),
      });
    },
    getUploadIconFromType(type) {
      return getUploadIconFromType(type);
    },
    formatFileSize(bytes) {
      return formatBytes(bytes);
    },
  },
  mounted() {
    this.imageUrl = this.placeholderImage;

    this.dragAndDropCapable = this.determineDragAndDropCapable();

    let draggedCounter = 0;

    if (this.dragAndDropCapable) {
      [
        'drag',
        'dragstart',
        'dragend',
        'dragover',
        'dragenter',
        'dragleave',
        'drop',
      ].forEach((event) => {
        this.$refs[this.getActionType('DragRef')].addEventListener(
          event,
          (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
          false
        );
      });

      this.$refs[this.getActionType('DragRef')].addEventListener(
        'drop',
        (e) => {
          if (this.pending) {
            return;
          }

          if (this.file) {
            this.resetUpload = true;
          }

          let files = e.dataTransfer.files;
          draggedCounter = 0;

          this.updateDragAndDrop(files);
          this.hasDragOver(false);
        }
      );

      this.$refs[this.getActionType('DragRef')].addEventListener(
        'dragenter',
        (e) => {
          if (this.pending) {
            return;
          }

          draggedCounter++;

          this.hasDragOver(true);
        }
      );

      this.$refs[this.getActionType('DragRef')].addEventListener(
        'dragleave',
        (e) => {
          if (this.pending) {
            return;
          }

          draggedCounter--;

          if (draggedCounter === 0) {
            this.hasDragOver(false);

            if (this.hasError) {
              this.hasError = false;
            }
          }
        }
      );

      if (this.complete) {
        this.updateState('file-uploaded');
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.uploads-table {
  max-height: 88px;
  overflow-y: auto;
}
</style>
