<script setup lang="ts">
import { AutoAssignType } from '@component-library/business-model/auto-assign';
import {
  type ReferenceDefault,
  checkIsReferenceDefault,
} from '@component-library/business-model/auto-assign';
import { App, GatherField } from '@component-library/gather';
import { FieldTypeIds } from '@component-library/fields';
import { computed, onMounted, ref, nextTick } from 'vue';
import axios from 'axios';
import useProjectId from '@/js/composables/useProjectId';
import { useToastStore } from '@component-library/store/toasts';
import InputSelect from '@component-library/components/InputSelect.vue';
import AlertBox from '@component-library/components/AlertBox.vue';

const projectId = useProjectId();
const toastStore = useToastStore();

const props = defineProps<{
  field: GatherField;
  fields: GatherField[];
}>();
const emit = defineEmits(['updateOptions']);

onMounted(async () => {
  await loadApps();
  loadAppFields();
});

const isLoadingOptions = ref(false);
const apps = ref<App[]>([]);
const linkedAppFields = ref<GatherField[]>([]);
const lastGatherFieldRequestIds = ref('');

const acceptableLinkedFieldTypes = [
  FieldTypeIds.TEXT,
  FieldTypeIds.NUMBER,
  FieldTypeIds.DATE,
  FieldTypeIds.DEPTH,
  FieldTypeIds.DROPDOWN,
  FieldTypeIds.CHECKBOX,
];

const referenceFields = computed(() => {
  return props.fields.filter((f) => f.field_type_id === FieldTypeIds.REFERENCE);
});

const assignDefaults = computed<ReferenceDefault[]>(() => {
  return (props.field.options?.defaults?.filter((d) =>
    checkIsReferenceDefault(d)
  ) ?? []) as ReferenceDefault[];
});

async function loadAppFields() {
  await nextTick();
  const referenceAppTitles = assignDefaults.value.map(
    (d) => d.referenceAppTitle
  );
  if (referenceAppTitles.length === 0) {
    return;
  }
  const referenceAppIds = apps.value
    .filter((a) => a.title && referenceAppTitles.includes(a.title))
    .map((a) => a.id);
  if (referenceAppIds.length === 0) {
    console.warn(
      'No referenced app ids',
      referenceAppIds,
      referenceAppTitles,
      apps.value
    );
    return;
  }
  const cacheKey = referenceAppIds.join('|');

  if (cacheKey === lastGatherFieldRequestIds.value) {
    return;
  }

  isLoadingOptions.value = true;
  let responseFields;
  try {
    responseFields = await axios.get('/api/template/fields', {
      params: { app_ids: referenceAppIds, with: ['section:label'] },
    });
  } catch (err) {
    toastStore.unexpected();
    isLoadingOptions.value = false;
    throw err;
  }

  lastGatherFieldRequestIds.value = cacheKey;
  linkedAppFields.value = responseFields.data.fields;

  await nextTick();
  isLoadingOptions.value = false;
}

async function loadApps() {
  if (!projectId) {
    throw new Error('Project ID not found');
  }

  const response = await axios.get(`/api/template/${projectId}/apps`, {
    params: { group: false },
  });
  apps.value = response.data.templates;
}

function getLinkedAppFields(referenceDefault: ReferenceDefault) {
  return linkedAppFields.value.filter(
    (f) =>
      acceptableLinkedFieldTypes.some((a) => a === f.field_type_id) &&
      apps.value.filter((a) => a.title === referenceDefault.referenceAppTitle)
  );
}

function handleAddClick() {
  const options = {
    ...props.field.options,
    defaults: [
      ...(props.field.options?.defaults ?? []),
      {
        type: AutoAssignType.REFERENCE,
        linkedFieldId: 'custom_title',
      } as ReferenceDefault,
    ],
  };
  emit('updateOptions', options);
}

function handleDeleteClick(index) {
  const cd = assignDefaults.value[index];
  const nextDefaults = [...(props.field.options?.defaults ?? [])];
  nextDefaults.splice(nextDefaults.indexOf(cd), 1);
  emit('updateOptions', {
    ...props.field.options,
    defaults: nextDefaults,
  });
}

function updateReferenceDefault(
  index: number,
  partialDefault: Partial<ReferenceDefault>
) {
  const referenceDefault = assignDefaults.value[index];
  const nextDefaults = [...(props.field.options?.defaults ?? [])];
  nextDefaults.splice(nextDefaults.indexOf(referenceDefault), 1, {
    ...referenceDefault,
    ...partialDefault,
  });
  emit('updateOptions', {
    ...props.field.options,
    defaults: nextDefaults,
  });

  loadAppFields();
}

function updateReferencedAppField(value: any, index: number) {
  if (!value) {
    return;
  }
  const referenceAppFieldId =
    typeof value === 'string' ? parseInt(value, 10) : value;
  updateReferenceDefault(index, {
    referenceAppFieldId,
    referenceAppTitle: props.fields.find((f) => f.id === referenceAppFieldId)
      ?.options?.template_tab_title,
  });
}

function updateLinkedField(value: any, index: number) {
  if (isLoadingOptions.value) {
    throw new Error('Cannot update linked field while loading options');
  }
  if (!value) {
    return;
  }
  const inputValue = value;
  if (inputValue === 'custom_title') {
    updateReferenceDefault(index, {
      linkedFieldId: 'custom_title',
    });
    return;
  }
  const linkedFieldId =
    typeof inputValue === 'string' ? parseInt(inputValue, 10) : inputValue;
  if (isNaN(linkedFieldId)) {
    return;
  }
  updateReferenceDefault(index, {
    linkedFieldId,
  });
}
</script>

<template>
  <div>
    <div class="d-flex flex-row align-items-center mb-3">
      <label class="form-label me-auto mb-0">
        Automatically assign a value from a linked app
      </label>
      <button
        v-if="assignDefaults.length == 0"
        type="button"
        class="btn btn-outline-primary btn-sm ms-2"
        @click="handleAddClick"
      >
        <i class="fas fa-plus m-1" />
      </button>
    </div>

    <template v-for="(referenceDefault, index) of assignDefaults">
      <div class="mb-4">
        <div class="d-flex align-items-center">
          <span class="flex-grow-1">
            Linked App:
            {{ referenceDefault.referenceAppTitle || '(not selected)' }}
          </span>
          <button
            v-if="referenceFields.length === 0"
            type="button"
            class="btn btn-outline-danger btn-sm"
            @click="handleDeleteClick(index)"
          >
            <i class="fas fa-trash-alt" />
          </button>
        </div>

        <AlertBox v-if="referenceFields.length === 0" class="mt-3">
          Please first add a "Linked Another App" field into this app.
        </AlertBox>
        <div v-else class="d-flex justify-items-ends">
          <!-- Select for a Dropdown field as an operand -->
          <InputSelect
            class="flex-grow-1"
            :value="referenceDefault.referenceAppFieldId"
            @input="(value) => updateReferencedAppField(value, index)"
          >
            <option v-for="f in referenceFields" :key="f.id" :value="f.id">
              {{ f.label }}
            </option>
          </InputSelect>

          <!-- Select for the options of a Dropdown field operand -->
          <select v-if="isLoadingOptions" class="form-control">
            <option>Loading ...</option>
          </select>
          <InputSelect
            v-else-if="referenceDefault.referenceAppFieldId"
            class="flex-grow-1"
            :value="referenceDefault.linkedFieldId"
            @input="(value) => updateLinkedField(value, index)"
          >
            <option :value="'custom_title'">Item Title</option>
            <option
              v-for="fieldValue in getLinkedAppFields(referenceDefault)"
              :key="fieldValue.id"
              :value="fieldValue.id"
            >
              <template v-if="fieldValue.section">
                {{ fieldValue.section.label }} -
              </template>
              {{ fieldValue.label }}
            </option>
          </InputSelect>

          <button
            type="button"
            class="btn btn-outline-danger"
            @click="handleDeleteClick(index)"
          >
            <i class="fas fa-trash-alt" />
          </button>
        </div>
      </div>
    </template>
  </div>
</template>
