import { BasemapId } from '../../lib/olbm/layer/basemap/types';
import { getAvailableBasemapApis } from '../../lib/olbm/layer/basemap/utils';
import {
  Integration,
  IntegrationId,
} from '../../lib/olbm/layer/service/integration';
import * as configs from './configs';

export { configs };

export type BasemapApi = {
  index: number;
  title: string;
};

export function getNearmapApiKey(integrations: Integration[]) {
  return integrations.find(
    (integration) => integration.id === IntegrationId.NEARMAP
  )?.apiKey;
}

export function getMetromapApiKey(integrations: Integration[]) {
  return integrations.find(
    (integration) => integration.id === IntegrationId.METROMAP
  )?.apiKey;
}

export function preprocessBasemapApi(apiType, integrations: Integration[]) {
  const { NEARMAP, METROMAP } = BasemapId;
  if (apiType.index === NEARMAP) {
    return {
      ...apiType,
      source: apiType.source.replace(
        '{API_KEY}',
        getNearmapApiKey(integrations)
      ),
    };
  } else if (apiType.index === METROMAP) {
    return {
      ...apiType,
      source: apiType.source.replace(
        '{API_KEY}',
        getMetromapApiKey(integrations)
      ),
    };
  }
  return apiType;
}

export function getBasemapApis(country, state?: string): BasemapApi[] {
  const configValues = Object.values(configs);
  return getAvailableBasemapApis(country, state).map((basemapApi) => {
    return configValues.find((cv) => cv.index === basemapApi.id) as BasemapApi;
  });
}

export function getBasemapTitle(id: BasemapId): string {
  return Object.values(configs).find((cv) => cv.index === id)?.title ?? '';
}
