<script setup lang="ts">
import {
  AutoAssignType,
  checkIsLookupDefault,
} from '@component-library/business-model/auto-assign';
import type { LookupDefault } from '@component-library/business-model/auto-assign';
import { computed, ref } from 'vue';
import ConfigModal from './AutoAssignByLookupConfigModal.vue';
import DictionaryModal from './AutoAssignByLookupDictionaryModal.vue';
import { GatherField } from '@component-library/gather';

const props = defineProps<{ field: GatherField; fields: GatherField[] }>();
const emit = defineEmits(['updateOptions']);

const isCmVisible = ref(false);
const isDmVisible = ref(false);
const lookupDefault = computed<LookupDefault | undefined>(
  () =>
    props.field.options?.defaults?.find((d) => checkIsLookupDefault(d)) as
      | LookupDefault
      | undefined
);

function handleAddClick() {
  isCmVisible.value = true;
}

function handleDeleteClick() {
  const nextDefaults = [...(props.field.options?.defaults ?? [])];
  nextDefaults.splice(nextDefaults.indexOf(lookupDefault.value!), 1);
  emit('updateOptions', {
    ...props.field.options,
    defaults: nextDefaults,
  });
}

function handleCmOK({ sourceFieldIds, dictionary }) {
  const nextDefaults = [...(props.field.options?.defaults ?? [])];
  const index = nextDefaults.findIndex((d) => checkIsLookupDefault(d));
  const ld = {
    type: AutoAssignType.LOOKUP,
    sourceFieldIds,
    dictionary,
  } as LookupDefault;
  if (index === -1) {
    nextDefaults.push(ld);
  } else {
    nextDefaults.splice(index, 1, ld);
  }

  const options = {
    ...props.field.options,
    defaults: nextDefaults,
  };
  emit('updateOptions', options);
  handleCmClose();
}

function handleCmClose() {
  isCmVisible.value = false;
}

function handleViewDictionaryClick() {
  isDmVisible.value = true;
}

function handleDmClose() {
  isDmVisible.value = false;
}
</script>

<template>
  <div>
    <div class="d-flex flex-row align-items-center mb-3">
      <label class="form-label me-auto mb-0">
        Automatically assign a default value by looking up a dictionary
      </label>
      <button
        v-if="!lookupDefault"
        type="button"
        class="btn btn-outline-primary btn-sm ms-2"
        @click="handleAddClick"
      >
        <i class="fas fa-plus m-1" />
      </button>
      <button
        v-else
        type="button"
        class="btn btn-outline-danger p-2"
        @click="handleDeleteClick"
      >
        <i class="fas fa-trash m-1" />
      </button>
    </div>

    <div v-if="lookupDefault" class="mb-3">
      <button
        type="button"
        class="btn btn-primary p-2"
        @click="handleViewDictionaryClick"
      >
        View Dictionary
      </button>
    </div>

    <ConfigModal
      v-if="isCmVisible"
      :field="props.field"
      :fields="props.fields"
      @OK="handleCmOK"
      @close="handleCmClose"
    />

    <DictionaryModal
      v-if="isDmVisible"
      :field="props.field"
      :fields="props.fields"
      @close="handleDmClose"
    />
  </div>
</template>
