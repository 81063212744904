<template>
  <responsive-or-slide-up
    title="Visibility"
    v-model="showConditionalOptions"
    data-cy="field-options"
    :mobileSize="mobileSize"
    v-if="fields.length > 0"
    class="mt-2 clickable"
  >
    <template #body>
      <div v-if="isAlwaysHiddenAvailable" class="form-check form-switch mb-2">
        <input
          type="checkbox"
          class="form-check-input"
          :id="alwaysHiddenCheckboxId"
          v-model="isAlwaysHidden"
        />
        <label class="form-label" :for="alwaysHiddenCheckboxId"
          >Always hidden</label
        ><InfoButton
          class="ms-2"
          info="When Always Hidden is on then the field keeps invisible in the form."
        />
      </div>

      <div
        v-if="!isAlwaysHidden"
        class="d-flex flex-row align-items-center mb-3"
      >
        <label class="form-label me-auto">
          Only show the current field when a condition is met
        </label>
        <button
          type="button"
          class="btn btn-outline-primary btn-sm ms-2"
          @click="addCondition"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Add a condition"
        >
          <i class="fas fa-plus m-1" />
        </button>
      </div>

      <FieldConditionList
        v-if="value.conditions"
        :value="value.conditions"
        :fields="fields"
        :groups="value.groups"
        @input="updateConditions"
        @removeCondition="removeCondition"
      />

      <div v-if="value.conditions.length > 0" class="btn-group mt-2">
        <button
          type="button"
          class="btn btn-secondary"
          :class="{
            active: !options.all_conditions_match,
          }"
          @click="setMatchingTechnique(false)"
        >
          At least one matches
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          :class="{
            active: options.all_conditions_match,
          }"
          @click="setMatchingTechnique(true)"
        >
          All conditions match
        </button>
      </div>
    </template></responsive-or-slide-up
  >
</template>
<script>
import { FieldTypeIds } from '@component-library/fields';
import ResponsiveOrSlideUp from './ResponsiveOrSlideUp.vue';
import FieldConditionList from './FieldConditionList.vue';
import InfoButton from '@component-library/components/InfoButton.vue';
import makeId from '@component-library/local-id.mjs';

export default {
  name: 'FieldConditionalOptions',
  components: {
    ResponsiveOrSlideUp,
    FieldConditionList,
    InfoButton,
  },
  props: {
    value: Object,
    field: Object,
    fields: Array,
    mobileSize: {
      required: true,
    },
  },
  mounted() {
    if (this.value.conditions.length > 0) this.showConditionalOptions = true;
  },
  computed: {
    options: {
      get() {
        return this.value;
      },
      set(updated) {
        this.$emit('input', updated);
      },
    },
    isAlwaysHiddenAvailable() {
      return this.field.field_type_id === FieldTypeIds.EXPRESSION;
    },
    isAlwaysHidden: {
      get() {
        return this.value.is_always_hidden;
      },
      set(value) {
        this.$emit('input', { ...this.value, is_always_hidden: value });
      },
    },
  },
  data: () => ({
    showConditionalOptions: false,
    alwaysHiddenCheckboxId: makeId(),
  }),
  methods: {
    updateConditions(conditions) {
      const options = { ...this.value, conditions };
      this.$emit('input', options);
    },
    addCondition() {
      this.$emit('add', {
        field_id: null,
        operator: '=',
        value: null,
      });
    },
    removeCondition(index) {
      const conditions = [...this.value.conditions].filter(
        (condition, aIndex) => aIndex != index
      );
      this.updateConditions(conditions);
    },
    setMatchingTechnique(all_conditions_match) {
      this.$emit('input', { ...this.value, all_conditions_match });
    },
  },
};
</script>
